import { DashboardTile } from '@application/dashboard/DashboardTile'
import { TileCompletionMode } from '@features/home/components/TileIndicator'
import { type MenuTile } from '@gqlTypes'
import { Box, Typography } from '@mui/material'
import { type ReactElement } from 'react'

interface Props {
	label: string
	tiles: MenuTile[]
}

export const DashboardSection = ({ label, tiles }: Props): ReactElement => {
	return (
		<Box>
			<Typography variant="h5" color="primary" fontWeight="bold" sx={{ ml: 2 }}>
				{label}
			</Typography>
			<Box>
				{tiles.map((tile) => (
					<DashboardTile
						tile={tile}
						key={tile.id}
						sx={{
							width: 370,
							height: null
						}}
						indicatorCompletionMode={TileCompletionMode.Chip}
					/>
				))}
			</Box>
		</Box>
	)
}
