import { DiscussionInfoCard } from './DiscussionInfoCard'
import { MessageInfoCard } from '../../shared/components/cards/MessageInfoCard'
import { ConversationFrame } from '@ui/conversation/ConversationFrame'
import { LocalLoader } from '../../shared/components/layout/LocalLoader'
import useDiscussionContent from './useDiscussionContent'
import { Grid, Stack } from '@mui/material'
import { type Dispatch, type SetStateAction, type ReactElement } from 'react'
import { type GetDiscussionUserFromOrganizationQuery } from '@gqlTypes'

interface Props {
	discussionUser: GetDiscussionUserFromOrganizationQuery['getDiscussionUserFromOrganization']
	setDiscussionUser?: Dispatch<SetStateAction<null>>
	userId: string
	displayName: string
}

export const DiscussionContent = ({ discussionUser, setDiscussionUser, userId, displayName }: Props): ReactElement => {
	const discussionContent = useDiscussionContent(discussionUser, setDiscussionUser, userId, displayName)

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={4}>
					<Stack spacing={2}>
						{discussionUser != null ? (
							<DiscussionInfoCard
								photoURL={discussionUser.photoURL}
								isRead={discussionContent.isRead}
								setIsRead={discussionContent.setIsRead}
								isFlagged={discussionContent.isFlagged}
								setIsFlagged={discussionContent.setIsFlagged}
								isArchived={discussionContent.isArchived}
								setIsArchived={discussionContent.setIsArchived}
								userOfferList={discussionContent.userOfferList}
								// eslint-disable-next-line react/jsx-handler-names
								handleMarkChange={discussionContent.handleMarkChange}
								// eslint-disable-next-line react/jsx-handler-names
								onCandidacySelect={discussionContent.onCandidacySelect}
								displayName={displayName}
								userId={userId}
							/>
						) : (
							<DiscussionInfoCard disabled displayName={discussionContent.displayName} userId={userId} />
						)}

						{discussionContent.messages.length > 0 && <MessageInfoCard messages={discussionContent.messages} />}
					</Stack>
				</Grid>

				<Grid item xs={8}>
					<ConversationFrame
						loading={discussionContent.isSendingMessage}
						onNewMessage={discussionContent.handleNewMessage}
					>
						{discussionContent.isLoadingMessages && <LocalLoader />}
						{!discussionContent.isLoadingMessages && discussionContent.decryptedMessages}
					</ConversationFrame>
				</Grid>
			</Grid>
		</>
	)
}
