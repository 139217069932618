import { ScreenId } from '@gqlTypes'
import { StudentsList } from './list/StudentsList.page'
import { StudentsDashboard } from './dashboard/StudentsDashboard.page'

enum Path {
	default = '/students',
	list = '/students/list',
	dashboard = '/students/dashboard'
}

const routes = [
	{
		path: [Path.default, Path.list],
		children: <StudentsList />
	},
	{
		path: Path.dashboard,
		children: <StudentsDashboard />
	}
]

export const StudentsRouter = {
	roleId: ScreenId.Students,
	paths: Path,
	routes
}
