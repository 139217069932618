import { useGetEducationLevelsQuery } from '@gqlTypes'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import InputTextField from '@shared/components/forms/fields/inputTextField'
import CardLayout from '@ui/core/CardLayout'
import { TableLayout } from '@ui/core/TableLayout'
import { type ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ChipSelector } from '../../../../shared/components/forms/fields/ChipSelector'
import { AuthorizedOrganizationsAutocomplete } from '../AuthorizedOrganizationsAutocomplete'
import { OfferTypeAutocomplete } from './OfferTypeAutocomplete'
import { UserFunctionSelect } from './UserFunctionSelect'
import { ContractDurationSelect } from './ContractDurationSelect'
import { WorkArrangementsSelect } from './WorkArrangementsSelect'
import { StartMonthsSelect } from './StartMonthsSelect.tsx'

export const OfferMainCard = (): ReactElement | null => {
	const { t } = useTranslation()
	const { control, errors } = useFormContext()
	const { data: dataEducationLevels, loading: isLoadingEducationLevels } = useGetEducationLevelsQuery()
	const educationLevels = dataEducationLevels?.getEducationLevels ?? []

	return (
		<CardLayout noDivider title={t('offers:details.main.title')} tooltip={t('offers:details.main.tooltip')}>
			<TableLayout>
				<AuthorizedOrganizationsAutocomplete />
				<OfferTypeAutocomplete />
				<TableLayoutRow translationKey="offers:details.main.label">
					<InputTextField control={control} name="label" error={errors.label} required />
				</TableLayoutRow>
				<UserFunctionSelect />
				<TableLayoutRow translationKey="offers:details.main.educationLevel">
					<ChipSelector
						name="educationLevelIds"
						items={educationLevels}
						isLoading={isLoadingEducationLevels}
						minSelectableItem={1}
					/>
				</TableLayoutRow>
				<WorkArrangementsSelect />
				<TableLayoutRow translationKey="offers:details.main.zipCode">
					<InputTextField
						control={control}
						name="zipCode"
						error={errors.zipCode}
						required
						patternValue={/\b\d{5}\b/g}
					/>
				</TableLayoutRow>
				<ContractDurationSelect />
				<StartMonthsSelect />
			</TableLayout>
		</CardLayout>
	)
}
