/* eslint-disable @typescript-eslint/no-explicit-any */
import { CandidateInfoCell } from '@shared/components/dataset/CandidateInfoCell'
import { useTranslation } from 'react-i18next'
import useDataUpdater from '../../../features/lists/hooks/useDataUpdater'
import ManagerSelect from '../../../features/lists/components/cells/ManagerSelect'
import { ROUTE_COMMUNITY_MEMBERS_DETAIL } from '../../../router/routes'
import { Typography } from '@mui/material'
import CommentModal from '../../../features/lists/components/modals/CommentModal/CommentModal'
import { type UserPosition } from '@gqlTypes'
import { StepsOverview } from '../../../features/lists/components/cells/StepsOverview'
import { BusinessUnitSelect } from '../../../features/lists/components/cells/BusinessUnitSelect'
import { AvatarLink } from '@shared/components/dataset/AvatarLink'
import { useRole } from '@shared/hooks/useRole'

export interface Cell {
	label: string
	width?: number
	align?: string
	renderCell: (param: any) => JSX.Element
	isDisplay?: boolean
}

export function useCommunityMembersColumns(query: (userPositionId: string, payload: any) => any): () => Cell[] {
	const updateData = useDataUpdater(query, 'UserPosition')
	const { t } = useTranslation()
	const translationKey = 'communityMembers:columns.'
	const { screens } = useRole()
	const editableFields: Cell[] = screens.communityMembers.hasWriteAccess
		? [
				{
					label: t(`${translationKey}manager`),
					align: 'center',
					renderCell: (userPosition: UserPosition) => (
						<ManagerSelect
							value={userPosition?.manager ?? ''}
							userPositionId={userPosition.id}
							onSelect={updateData}
							noneLabel={t('communityMembers:cell.manager.none')}
						/>
					)
				},
				{
					label: t(`${translationKey}businessUnit`),
					align: 'center',
					renderCell: (userPosition: UserPosition) => (
						<BusinessUnitSelect
							value={userPosition.businessUnit ?? ''}
							userPositionId={userPosition.id}
							onSelect={updateData}
						/>
					)
				},
				{
					label: t(`${translationKey}comment`),
					width: 152,
					renderCell: (userPosition: UserPosition) => (
						<CommentModal id={userPosition.id} defaultValue={userPosition.comment ?? ''} onValidate={updateData} />
					)
				}
				// eslint-disable-next-line no-mixed-spaces-and-tabs
		  ]
		: []

	const render = (): Cell[] => {
		return [
			{
				label: '',
				width: 78,
				align: 'center',
				renderCell: (userPosition: UserPosition) => (
					<AvatarLink
						hasLink
						route={ROUTE_COMMUNITY_MEMBERS_DETAIL}
						routeParams={{ userPositionId: userPosition.id }}
						tooltipTitle={t(`${translationKey}link`)}
					/>
				)
			},
			{
				label: t(`${translationKey}member`),
				width: 200,
				align: 'center',
				renderCell: (userPosition: UserPosition) => (
					<CandidateInfoCell
						firstname={userPosition?.userInfo?.firstName ?? ''}
						lastname={userPosition?.userInfo?.lastName ?? ''}
					/>
				)
			},
			{
				label: t(`${translationKey}label`),
				width: 200,
				align: 'center',
				renderCell: (userPosition: UserPosition) => <Typography>{userPosition?.orgaPosition?.label}</Typography>
			},
			{
				label: t(`${translationKey}publicTitle`),
				width: 200,
				align: 'center',
				renderCell: (userPosition: UserPosition) => <Typography>{userPosition?.userInfo?.publicTitle ?? ''}</Typography>
			},
			{
				label: t(`${translationKey}currentCompany`),
				width: 200,
				align: 'center',
				renderCell: (userPosition: UserPosition) => (
					<Typography>{userPosition?.userInfo?.currentCompany ?? ''}</Typography>
				)
			},
			{
				label: t(`${translationKey}location`),
				width: 200,
				align: 'center',
				renderCell: (userPosition: UserPosition) => <Typography>{userPosition.location}</Typography>
			},
			{
				label: t(`${translationKey}steps`),
				width: 100,
				align: 'center',
				renderCell: (userPosition: UserPosition) => {
					const steps = userPosition.stepsIntegrations.map((stepIntegration) => ({
						isCompleted: stepIntegration.isCompleted,
						icon: stepIntegration.icon,
						label: stepIntegration.organizationLabel
					}))
					return <StepsOverview steps={steps} />
				}
			},
			...editableFields
		]
	}

	return render
}
