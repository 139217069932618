import { type UserRef } from '@gqlTypes'
import { type ReactElement, type ReactNode } from 'react'
import { UserAvatar } from '../UserAvatar'
import { AvatarCard } from './AvatarCard'

interface Props {
	userRef?: UserRef | null
	children?: ReactNode
	actions?: (isHovered: boolean) => ReactNode
	to?: string
	navigationState?: Record<string, unknown>
	linkLabel?: string
	hasLink?: boolean
	avatarComponent?: ReactNode
}

export const UserCard = ({
	userRef,
	actions,
	children,
	to,
	linkLabel = '',
	hasLink = true,
	avatarComponent,
	navigationState
}: Props): ReactElement | null => {
	if (userRef == null) return null
	return (
		<AvatarCard
			avatar={avatarComponent ?? <UserAvatar user={userRef} />}
			title={userRef.displayName ?? ''}
			{...{ actions, to, linkLabel, hasLink, navigationState }}
		>
			{children}
		</AvatarCard>
	)
}
