import { type ReactElement } from 'react'
import {
	type GetInterviewDetailsQuery,
	useCompleteMotivationTestMutation,
	useUpdateMotivationTestMutation
} from '@gqlTypes'
import { useTranslation } from 'react-i18next'
import { useInterviewState } from '@domains/interviews/details/useInterviewState'
import { ErrorHandler } from '#helpers/error.helper'
import { Severities } from '../../constants/Severities'
import { useSnackbar } from 'notistack'
import { useFormContext } from 'react-hook-form'
import { type MotivationFormInputs } from './MotivationForm'
import { LoadingButton } from '@mui/lab'

interface Props {
	interview: GetInterviewDetailsQuery['getInterview']
	motivationTestId: string
}

export const SaveMotivationButton = ({ interview, motivationTestId }: Props): ReactElement | null => {
	const interviewId = interview.id ?? ''
	const { isMotivationEditable } = useInterviewState(interview)
	const { enqueueSnackbar } = useSnackbar()
	const [updateMotivationTest, { loading: isUpdating }] = useUpdateMotivationTestMutation()
	const [completeMotivationTest] = useCompleteMotivationTestMutation()

	const { t } = useTranslation()
	const { getValues } = useFormContext<MotivationFormInputs>()

	const saveMotivation = (): void => {
		;(async () => {
			const data = getValues()
			const motivationTestInput = {
				interviewId,
				motivationsLabels: Object.entries(data.labels ?? {}).map(([id, labels]) => {
					return { id, labels: labels ?? [] }
				}),
				motivationsQuestions: Object.entries(data.answers ?? {}).map(([id, answer]) => {
					return { id, answer: answer ?? '' }
				})
			}
			try {
				const { data } = await updateMotivationTest({ variables: { motivationTestId, motivationTestInput } })
				await completeMotivationTest({
					variables: {
						motivationTestId: data?.saveMotivationTest?.id ?? ''
					}
				})
				enqueueSnackbar(t('translation:interviewDetailPage.submitInterview.success'), { variant: Severities.success })
			} catch (error) {
				enqueueSnackbar(t('translation:interviewDetailPage.submitInterview.error'), { variant: Severities.error })
			}
		})().catch(ErrorHandler)
	}

	if (!isMotivationEditable) return null

	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<LoadingButton onClick={saveMotivation} variant="contained" loading={isUpdating}>
				{t('translation:interviewDetailPage.save')}
			</LoadingButton>
		</div>
	)
}
