import { useEffect, useState } from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { userService } from '../../../services/Services'
import { type SoftSKillIndicators } from '@servicesUserService'

interface Props {
	userId?: string
}

interface Return {
	isLoading: boolean
	keyIndicators: SoftSKillIndicators
}

export const useKeyIndicators = ({ userId = '' }: Props): Return => {
	const errorHandler = useErrorHandler()

	const [isLoading, setIsLoading] = useState(true)
	const [keyIndicators, setKeyIndicators] = useState<SoftSKillIndicators>({})

	// Get data for softkills indicators
	useEffect(() => {
		if (userId === '') return
		const getIndicator = async (): Promise<void> => {
			setIsLoading(true)
			const indicators = await userService.getIndicators(userId)
			setKeyIndicators(indicators)
			setIsLoading(false)
		}
		getIndicator().catch(errorHandler)
	}, [errorHandler, userId])

	return {
		isLoading,
		keyIndicators
	}
}
