import { type GetInterviewDetailsQuery, type TrackSoftskill, useMeQuery } from '@gqlTypes'
import { indexById, indexByProperty, indexPropertyById } from '../../../helper/array.helper'

type Track = GetInterviewDetailsQuery['getInterview']['track']
type TrackSkills = GetInterviewDetailsQuery['getInterview']['track']['trackSkills']
type Template = GetInterviewDetailsQuery['getInterview']['template']

interface Props {
	track: Track
	userIntegration: GetInterviewDetailsQuery['getInterview']['userIntegration']
	interview: GetInterviewDetailsQuery['getInterview']
}

export interface InterviewSkill extends TrackSoftskill {
	id: string
	mandatory: boolean
	expectedScore: number
	selfEvaluationScore: number
	feedbackAverage: number
	interviewScore: number
	imageResponse: number
	comment: string
	imageResponseDetailedLabel: string
	selfEvaluatedDetailedLabel: string
	label: string
	descriptionOrga: string
}

interface UseInterviewSkills {
	normalSkillList: InterviewSkill[]
	orgaOnlySkillList: InterviewSkill[]
	mandatorySkills: Record<string, boolean>
	skillScores: Record<string, number>
	skillComments: Record<string, string>
}

export const useInterviewSkills = ({ track, userIntegration, interview }: Props): UseInterviewSkills => {
	const { data } = useMeQuery()
	const isFeedbackAverageVisible = data?.me?.organization.isFeedbackAverageVisible ?? false

	const selfEvaluatedSoftSkill = interview.softSkillSelfEvaluated ?? []
	const imageReturnResponses = interview.assessorFeedbacks ?? []
	const interviewSoftskills = interview.softSkills ?? []
	const indexedSelfEvaluatedDetailedLabel = interview.answersDetailedLabelsBySkill ?? {}
	const indexedSkillsRestitutions = isFeedbackAverageVisible
		? indexPropertyById(userIntegration.sharedSoftskillsRestitution, 'score')
		: {}
	const indexedImageResponses = indexByProperty(imageReturnResponses, 'softSkillId')
	const indexedInterviewSoftskills = indexById(interviewSoftskills)

	const interviewSkills: InterviewSkill[] = buildTrackSkills(track, interview.template).map((skill) => {
		const { label, descriptionOrga } = skill
		const skillId = skill.id ?? ''
		const interviewSoftskill = indexedInterviewSoftskills[skillId] ?? {}
		const imageResponseObject = indexedImageResponses[skillId] ?? {}
		const selfEvaluationScore = selfEvaluatedSoftSkill[skillId]?.score ?? null
		const feedbackAverage = indexedSkillsRestitutions[skillId] ?? null
		const interviewScore = interviewSoftskill.score ?? 0
		const imageResponse = imageResponseObject.score
		const comment = interviewSoftskill.comment ?? imageResponseObject.message ?? ''
		const imageResponseDetailedLabel = imageResponseObject.answersDetailedLabel
		const selfEvaluatedDetailedLabel = indexedSelfEvaluatedDetailedLabel[skill.id] ?? ''
		return {
			...skill,
			label,
			descriptionOrga,
			selfEvaluationScore,
			feedbackAverage,
			interviewScore,
			imageResponse,
			comment,
			imageResponseDetailedLabel,
			selfEvaluatedDetailedLabel
		}
	})

	const normalSkillList = interviewSkills.filter(({ orgaOnly }) => orgaOnly === false)
	const orgaOnlySkillList = interviewSkills.filter(({ orgaOnly }) => orgaOnly === true)
	const mandatorySkills = indexPropertyById(interviewSkills, 'mandatory')
	const skillScores = indexPropertyById(interviewSkills, 'interviewScore')
	const skillComments = indexPropertyById(interviewSkills, 'comment')

	return {
		skillScores,
		mandatorySkills,
		normalSkillList,
		orgaOnlySkillList,
		skillComments
	}
}

const buildTrackSkills = (track: Track, template?: Template): TrackSkills => {
	const hasTempalteSkills = template?.trackSkills != null && template?.trackSkills.length > 0
	const skills = hasTempalteSkills ? template.trackSkills : track.trackSkills

	const hasHardSkills = template?.hasHardSkills ?? true
	const hasSoftSkills = template?.hasSoftSkills ?? true
	return skills.filter(({ isHardSkill }) => (hasHardSkills && isHardSkill) || (hasSoftSkills && !isHardSkill))
}
