import { firestore } from '../firebase/firebase.helper'
import Collections from '../dao/Collections'
import { readQuerySnapshot } from '../firebase/firestore.helper'

export interface SoftSKillIndicators {
	selfEvaluationRange: number
	selfEvaluationStrengths: number
	selfEvaluationWeaknesses: number
	selfEvaluationAverage: number
	requestsCount: number
	returnsCount: number
	sharedRequestsCount: number
	returnsAverage: number
}

export default class UserService {
	async getIndicators(userId: string): Promise<SoftSKillIndicators> {
		const indicatorSnapshot = await firestore()
			.collection(Collections.Users.name)
			.doc(userId)
			.collection(Collections.Users.Indicators.name)
			.get()

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const final: any = {}
		readQuerySnapshot(indicatorSnapshot).forEach((indicator: { id: string; value: string }) => {
			final[indicator.id] = indicator.value
		})
		return final as SoftSKillIndicators
	}
}
