import CardLayout from '../core/CardLayout'
import { TextField } from '../core/TextField'
import { useTranslation } from 'react-i18next'
import { IconButton, useTheme } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import Tooltip from '@mui/material/Tooltip'
import { type ChangeEvent, type FormEvent, type ReactElement, type ReactNode, useState } from 'react'
import { Conversation } from '../conversation/Conversation'
import { LocalLoader } from '@shared/components/layout/LocalLoader'

interface Props {
	onNewMessage: (message: string) => void
	loading: boolean
	children: ReactNode
}

export const ConversationFrame = ({ onNewMessage, loading, children }: Props): ReactElement => {
	const { t } = useTranslation(['translation'])
	const theme = useTheme()
	const [typedMessage, setTypedMessage] = useState('')

	const handleSubmit = (e: FormEvent): void => {
		e.preventDefault()

		if (typedMessage.length < 1) return

		if (onNewMessage != null) onNewMessage(typedMessage)

		setTypedMessage('')
	}

	return (
		<CardLayout
			sx={{
				height: '75vh',
				display: 'flex',
				flexDirection: 'column'
			}}
		>
			<Conversation
				style={{
					paddingLeft: 15,
					paddingRight: 15,
					flex: 1,
					overflow: 'auto'
				}}
			>
				{children}
			</Conversation>
			<form
				style={{
					background: theme.palette.darkBlue.light,
					color: 'white',
					padding: '10px 25px',

					display: 'flex',
					alignItems: 'center',
					flexShrink: 0
				}}
				onSubmit={handleSubmit}
			>
				<TextField
					disabled={loading}
					style={{
						background: 'rgba(255,255,255,0.9)',
						borderRadius: 5,
						flex: 1,
						marginRight: 25
					}}
					variant="outlined"
					fullWidth
					placeholder={t('translation:conversationFrame.sendMessage')}
					value={typedMessage}
					onChange={(e: ChangeEvent<HTMLInputElement>) => {
						setTypedMessage(e.target.value)
					}}
				/>
				<div style={{ width: 48, height: 48 }}>
					<LocalLoader load={loading}>
						<Tooltip title={t('translation:conversationFrame.sendMessageTooltip')}>
							<IconButton type="submit" disabled={loading} color="inherit">
								<SendIcon />
							</IconButton>
						</Tooltip>
					</LocalLoader>
				</div>
			</form>
		</CardLayout>
	)
}
