import { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import { useTranslation } from 'react-i18next'
import { DialogSection } from '@shared/components/modal/dialogSection'
import FormatAlignLeft from '@mui/icons-material/FormatAlignLeft'
import { useForm } from 'react-hook-form'
import { TextField } from '@ui/core/TextField'
import { makeStyles } from 'tss-react/mui'
import SaveButton from '@shared/components/buttons/SaveButton'
import { ErrorHandler } from '#helpers/error.helper'
import { useMeQuery } from '@gqlTypes'
import { useSnackbar } from 'notistack'
import { Severities } from '../../../../../constants/Severities'
import { IconAction } from '@shared/icon/IconAction'

interface Props {
	id: string
	defaultValue?: string
	onValidate?: (id: string, key: string, value: string) => void
	onSelect?: (value: string) => void
	isHovered?: boolean
}

export default function CommentModal({
	id,
	defaultValue,
	onValidate,
	onSelect = () => null,
	isHovered
}: Props): JSX.Element | null {
	const [isLoading, setIsLoading] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const { t } = useTranslation()
	const { getValues, register, formState } = useForm({ mode: 'onChange' })
	const { classes } = useStyles()
	const { loading: isMeLoading, data } = useMeQuery()
	const { enqueueSnackbar } = useSnackbar()

	const isExternal = data?.me?.role?.isExternal ?? true
	const hasNoEmptySpaceRegex = /(?!^$)([^\s])/
	const hasValidDefaultValue = defaultValue != null && hasNoEmptySpaceRegex.test(defaultValue)
	let color = hasValidDefaultValue ? '#0A2E56' : '#BDBDBD'
	if (isHovered === false && !hasValidDefaultValue) color = 'transparent'

	const validate = (): void => {
		;(async () => {
			setIsLoading(true)
			const comment: string = getValues('comment') ?? ''
			if (onValidate != null) onValidate(id, 'comment', comment)
			onSelect(comment)
			setIsLoading(false)
			setIsOpen(false)
		})().catch((e) => {
			ErrorHandler(e)
			enqueueSnackbar(t('modal:intergrationComment.error'), { variant: Severities.error })
		})
	}

	if (isMeLoading || isExternal) return null

	return (
		<>
			<IconAction
				tooltip={t('translation:commons.comment')}
				onClick={() => {
					setIsOpen(true)
				}}
			>
				<FormatAlignLeft style={{ color }} />
			</IconAction>
			<Dialog
				open={isOpen}
				onClose={() => {
					setIsOpen(false)
				}}
			>
				<DialogTitle>{t('candidates:columns.comment')}</DialogTitle>
				<Divider />
				<form>
					<div className={classes.form}>
						<DialogSection vertical>
							<TextField
								label={t('candidates:columns.comment')}
								variant="outlined"
								fullWidth
								multiline
								rows={4}
								inputRef={register({ pattern: /\S/ })}
								name="comment"
								defaultValue={defaultValue}
								disabled={isLoading}
								autoFocus
							/>
						</DialogSection>
						<SaveButton
							onClick={validate}
							disabled={!formState.isDirty || !formState.isValid}
							title={t('tracks:details.bar.button.save')}
							isSaving={isLoading}
						/>
					</div>
				</form>
			</Dialog>
		</>
	)
}

const useStyles = makeStyles()(() => ({
	form: {
		textAlign: 'center',
		width: '100%',
		marginBottom: 10
	}
}))
