import { useMeQuery } from '@gqlTypes'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import InputSelectField from '@shared/components/forms/fields/InputSelectField'
import { type ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const UserFunctionSelect = (): ReactElement | null => {
	const { t } = useTranslation()
	const { data, loading } = useMeQuery()
	const { control, errors } = useFormContext()
	const items = data?.me?.organization?.matchingRestrictions?.userFunctions ?? []
	if (items.length <= 1) return null

	return (
		<TableLayoutRow translationKey="offers:details.main.function">
			<InputSelectField
				isLoading={loading}
				name="userFunction"
				items={items}
				control={control}
				displayKey="name"
				rules={{ required: t('form:errors.required') }}
				errors={errors}
			/>
		</TableLayoutRow>
	)
}
