import { type GetMenuTilesQuery } from '@gqlTypes'
import { Grid, LinearProgress, Typography } from '@mui/material'
import { ConditionalWrapper } from '@shared/components/ConditionalWrapper'
import { type ReactElement, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PercentageChip } from './PercentageChip'

export enum TileCompletionMode {
	'Chip',
	'Bar'
}

interface Props {
	pathname: string
	indicator: GetMenuTilesQuery['getMenuTiles'][0]['indicators'][0]
	completionMode?: TileCompletionMode
	isPrimary?: boolean
}

export const TileIndicator = ({
	pathname,
	indicator,
	completionMode = TileCompletionMode.Chip,
	isPrimary = false
}: Props): ReactElement => {
	const [isHovered, setIsHovered] = useState(false)
	const { label, value, color, frontFilters, completionPercentage } = indicator
	const history = useHistory()

	const hasFilters = frontFilters.length > 0
	const isFocused = isHovered && hasFilters

	const isBar = completionMode === TileCompletionMode.Bar

	return (
		<Grid item xs={isPrimary ? 12 : 6} sx={{ paddingLeft: 1, paddingRight: 1 }}>
			<ConditionalWrapper
				condition={frontFilters.length > 0}
				wrapper={(children) => {
					return (
						<div
							onMouseOver={() => {
								setIsHovered(true)
							}}
							onMouseLeave={() => {
								setIsHovered(false)
							}}
							onClick={(e) => {
								e.preventDefault()
								history.push(pathname, { routeFilters: frontFilters })
							}}
							style={{ textDecoration: 'none', cursor: 'pointer' }}
						>
							{children}
						</div>
					)
				}}
			>
				<Typography
					variant="h3"
					sx={{
						fontSize: 34,
						color,
						fontWeight: 'bold',
						textDecoration: isFocused ? 'underline' : 'none'
					}}
				>
					{value}
				</Typography>
				<Typography sx={{ fontSize: isBar ? 13 : null, color: 'grays.gray2' }}>{label}</Typography>
				{completionMode === TileCompletionMode.Chip ? (
					<PercentageChip color={color} value={completionPercentage} />
				) : null}
				{isBar && !isPrimary ? (
					<LinearProgress
						variant="determinate"
						value={completionPercentage}
						sx={{
							mt: 0.25,
							height: 4,
							backgroundColor: `${color}80`,
							'.MuiLinearProgress-barColorPrimary': { backgroundColor: color }
						}}
					/>
				) : null}
			</ConditionalWrapper>
		</Grid>
	)
}
