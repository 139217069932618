import useUserBContext from '@shared/hooks/useUserBContext'
import { type GetInterviewDetailsQuery } from '@gqlTypes'
import { useRole } from '@shared/hooks/useRole'

interface InterviewState {
	isReadOnly: boolean
	isEditable: boolean
	isAssessorSigned: boolean
	isAssessedSigned: boolean
	hasStartedSigning: boolean
	isMotivationEditable: boolean
	isNotReady: boolean
	isInterviewAssessee: boolean
}

export const useInterviewState = (interview: GetInterviewDetailsQuery['getInterview']): InterviewState => {
	const userB = useUserBContext()
	const { screens } = useRole()
	const isAssessorSigned = interview.signatures.assessor.isSigned
	const isAssessedSigned = interview.signatures.assessed.isSigned
	const hasStartedSigning = isAssessorSigned || isAssessedSigned
	const isReadOnly = userB.id === interview.userId || hasStartedSigning || interview.isArchived
	const isEditable = !isReadOnly
	const isInterviewAssessee = userB.id === interview.userId
	const isMotivationEditable =
		(userB.id === interview.userId && !hasStartedSigning && !interview.isArchived) ||
		screens.interviewGrid.hasAdminAccess
	const isNotReady = userB.id === interview.userId && interview.isDraft

	return {
		isReadOnly,
		isAssessorSigned,
		isAssessedSigned,
		hasStartedSigning,
		isEditable,
		isMotivationEditable,
		isNotReady,
		isInterviewAssessee
	}
}
