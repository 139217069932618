import { type ReactElement, useState } from 'react'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import { useTranslation } from 'react-i18next'
import { InterviewsFilterBar } from './InterviewsFilterBar'
import useCheckRoleSecurity from '@shared/hooks/useRoleSecurity'
import { type GetUserInterviewListNodesFragment, ScreenId, useGetUserInterviewListQuery, useMeQuery } from '@gqlTypes'
import { useFilters } from '@shared/hooks/useFilters'
import { Container } from '@mui/material'
import { CardList } from '@features/lists/components/CardList'
import { InterviewCard } from './InterviewCard'
import { useDefaultFilter } from '@shared/hooks/useDefaultFilter'
import { searchQuery } from '@features/lists/components/Search'
import { useOrganization } from '@domains/organization/useOrganization'

export const Interviews = (): ReactElement => {
	const { data: meData } = useMeQuery()
	const { t } = useTranslation(['lists'])
	useDocumentTitle(t('translation:interviewList.title'))
	useCheckRoleSecurity(ScreenId.Interviews)
	const [isAllRowsSelected, setIsAllRowsSelected] = useState(false)
	const { filters } = useFilters()
	const { organization } = useOrganization()
	const hasPdfExport = organization?.hasPdfExport ?? false
	const variables = {
		limit: 12,
		filters: filters.filter((filter) => filter.value !== 'empty'),
		cursor: '',
		searchQuery: searchQuery()
	}
	const isInterviewLabelAvailable = meData?.me?.organization.isInterviewLabelAvailable ?? false

	const { loading, data, refetch, fetchMore } = useGetUserInterviewListQuery({
		variables
	})
	useDefaultFilter({ refetch })

	let { nodes, cursor, hasNextPage, count = 0 } = data?.getUserInterviewList ?? {}
	nodes = nodes ?? []
	cursor = cursor ?? ''
	hasNextPage = hasNextPage ?? false

	return (
		<Container maxWidth="xl">
			<CardList
				emptyStateLabel={t('interviews:dataGrid.empty')}
				renderItem={(item, isSelected, selectRow) => (
					<InterviewCard
						key={item?.id}
						interview={item as GetUserInterviewListNodesFragment}
						isSelected={isSelected}
						selectRow={selectRow}
						isInterviewLabelAvailable={isInterviewLabelAvailable}
					/>
				)}
				isAllRowsSelected={isAllRowsSelected}
				setIsAllRowsSelected={setIsAllRowsSelected}
				dataList={nodes}
				isLoading={loading}
				fetchMore={fetchMore}
				refetch={refetch}
				hasSearch
				cursor={cursor}
				hasNextPage={hasNextPage}
				title={t('interviews:title', { count })}
				toolbar={
					<InterviewsFilterBar
						isAllRowsSelected={isAllRowsSelected}
						refetch={refetch}
						dataList={nodes}
						count={count}
						hasPdfExport={hasPdfExport}
					/>
				}
			/>
		</Container>
	)
}
