import { useCustomTranslation } from '#translations/useCustomTranslation'
import { type ApolloQueryResult } from '@apollo/client'
import { Mode } from '@application/dashboard/ModeToggle'
import { Filters } from '@features/lists/components/Filters'
import BusinessUnitFilter from '@features/lists/components/Filters/BusinessUnitFilter'
import { CheckboxInput } from '@features/lists/components/Filters/CheckboxInput'
import { Fullname } from '@features/lists/components/Filters/Fullname'
import { InterviewTypeStateFilter } from '@features/lists/components/Filters/InterviewTypeStateFilter'
import LocationFilter from '@features/lists/components/Filters/LocationsFilter'
import ManagersFilter from '@features/lists/components/Filters/ManagersFilter'
import PositionStatusFilter from '@features/lists/components/Filters/PositionStatusFilter'
import { PromotionFilter } from '@features/lists/components/Filters/PromotionFilter'
import RiskFilter from '@features/lists/components/Filters/RiskFilter'
import { TextFilter } from '@features/lists/components/Filters/TextFilter'
import { TrackPositionFilter } from '@features/lists/components/Filters/TrackPositionFilter'
import { FiltersContent } from '@features/lists/components/FiltersContent'
import { type ReactElement } from 'react'

interface Props {
	refetch: () => Promise<ApolloQueryResult<unknown>>
	mode?: Mode
}

export const StudentsFilters = ({ refetch, mode = Mode.List }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	return (
		<Filters refetch={refetch}>
			<FiltersContent>
				<Fullname />
				<TextFilter keyName="userInfo.externalId" label={t('lists:filter.externalId')} />
				<TrackPositionFilter keyName="orgaPosition.id" keyLabel="schoolTrack" />
				<LocationFilter label={t('lists:filter.campus.label')} allValueLabel={t('lists:filter.campus.allLabel')} />
				<ManagersFilter keyLabel="referents" />
				<ManagersFilter keyName="externalAssessorId" keyLabel="tutors" />
				<BusinessUnitFilter isSchool />
				<PromotionFilter />
				<PositionStatusFilter />
				<RiskFilter />
				<TextFilter keyName="userInfo.currentCompanyLowercase" label={t('lists:filter.currentCompany')} />
				{mode === Mode.List ? <CheckboxInput keyName="isArchived" translationKey="lists:filter.archived" /> : null}
				<CheckboxInput keyName="isActive" translationKey="lists:filter.isActive" />
				<CheckboxInput
					keyName="isExternalAssessorValidated"
					translationKey="lists:filter.isExternalAssessorValidated"
				/>
				<InterviewTypeStateFilter />
			</FiltersContent>
		</Filters>
	)
}
