import { type ReactElement, useCallback } from 'react'
import { KeyIndicatorCard } from './KeyIndicatorCard'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/material'
import { type SoftSKillIndicators } from '@servicesUserService'

interface Props {
	indicators: SoftSKillIndicators | null
}

export const KeyIndicatorsCardRow = ({ indicators }: Props): ReactElement => {
	const { t } = useTranslation()

	const calculateIdentification = useCallback(
		(selfEvaluationRange?: number, selfEvaluationStrengths?: number, selfEvaluationWeaknesses?: number) => {
			const indicator = 'identification'
			let text
			let type

			if (selfEvaluationRange == null || selfEvaluationStrengths == null || selfEvaluationWeaknesses == null) {
				type = 'empty'
			} else if (selfEvaluationRange >= 2) {
				type = 'good'
				text = t(`details:indicators.${indicator}.${type}`, {
					strengths: selfEvaluationStrengths,
					weaknesses: selfEvaluationWeaknesses
				})
			} else type = 'bad'

			return {
				title: t(`details:indicators.${indicator}.${type}`),
				indicator,
				colorType: type,
				text
			}
		},
		[t]
	)

	const calculateSelfEvaluation = useCallback(
		(selfEvaluationAverage?: number) => {
			const indicator = 'selfEvaluation'
			let type
			let text = ''
			let title = t(`details:indicators.${indicator}.title`)

			if (selfEvaluationAverage == null) {
				title = t(`details:indicators.${indicator}.empty`)
				type = 'empty'
			} else if (selfEvaluationAverage <= 1.33) {
				text = t(`details:indicators.${indicator}.bad`)
				type = 'average'
			} else if (selfEvaluationAverage >= 3.66) {
				text = t(`details:indicators.${indicator}.average`)
				type = 'average'
			} else {
				text = t(`details:indicators.${indicator}.good`)
				type = 'good'
			}

			return {
				title,
				indicator,
				colorType: type,
				text
			}
		},
		[t]
	)

	const calculateImageReturn = useCallback(
		(requestsCount?: number, returnsCount?: number, sharedRequestsCount?: number) => {
			const indicator = 'imageReturn'
			let type
			if (returnsCount == null) type = 'bad'
			else if (returnsCount > 1) type = 'good'
			else if (returnsCount === 1) type = 'average'
			else type = 'bad'

			const text = (
				<>
					{t('details:indicators.requestsCount', { count: requestsCount ?? 0 })}
					<br />
					{t('details:indicators.returnsCount', { count: returnsCount ?? 0 })} <br />
					{t('details:indicators.sharedRequestsCount', { count: sharedRequestsCount ?? 0 })}
				</>
			)

			return {
				title: '',
				text,
				indicator,
				colorType: type
			}
		},
		[t]
	)

	const calculateAlignment = useCallback(
		(returnsCount?: number, returnsAverage?: number, selfEvaluationAverage?: number) => {
			const indicator = 'alignment'
			let type = 'good'
			const alignment = (returnsAverage ?? 0) - (selfEvaluationAverage ?? 0)
			if (returnsCount === 0 || returnsCount == null) type = 'empty'
			else if (alignment > 1.33) type = 'average'
			else if (alignment < -1.33) type = 'bad'

			return {
				title: t(`details:indicators.alignment.${type}`),
				indicator,
				colorType: type,
				text: ''
			}
		},
		[t]
	)

	const calculateIndicators = useCallback(() => {
		return [
			calculateIdentification(
				indicators?.selfEvaluationRange,
				indicators?.selfEvaluationStrengths,
				indicators?.selfEvaluationWeaknesses
			),
			calculateSelfEvaluation(indicators?.selfEvaluationAverage),
			calculateImageReturn(indicators?.requestsCount, indicators?.returnsCount, indicators?.sharedRequestsCount),
			calculateAlignment(indicators?.returnsCount, indicators?.returnsAverage, indicators?.selfEvaluationAverage)
		]
	}, [indicators, calculateIdentification, calculateSelfEvaluation, calculateImageReturn, calculateAlignment])

	return (
		<Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }} mb={5}>
			{calculateIndicators().map((card) => (
				<KeyIndicatorCard
					colorType={card.colorType}
					indicator={card.indicator}
					title={card.title}
					text={card.text}
					key={card.indicator}
				/>
			))}
		</Stack>
	)
}
