import { Typography } from '@mui/material'
import { type ReactElement } from 'react'
interface Props {
	color?: string
	text?: string
	colorText?: string
	title?: string
}

export const LegendItem = ({ color, text, colorText, title }: Props): ReactElement => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row'
			}}
		>
			<Typography
				style={{
					fontStyle: 'italic',
					textDecoration: 'underline'
				}}
			>
				{title}
			</Typography>
			<Typography
				style={{
					fontStyle: 'italic',
					marginLeft: 5
				}}
				color={color}
			>
				{colorText}
			</Typography>
			<Typography
				style={{
					fontStyle: 'italic',
					marginLeft: 5,
					marginRight: 20
				}}
			>
				{text}
			</Typography>
		</div>
	)
}
