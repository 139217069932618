import theme from '@ui/theme'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import RecordVoiceOverOutlinedIcon from '@mui/icons-material/RecordVoiceOverOutlined'
import ExposureIcon from '@mui/icons-material/Exposure'
import Typography from '@mui/material/Typography'
import { Stack, type SvgIconTypeMap } from '@mui/material'
import { type ReactNode, type ReactElement } from 'react'
import { type OverridableComponent } from '@mui/material/OverridableComponent'

const colors: Record<string, string> = {
	average: theme.palette.darkBlue.light,
	good: theme.palette.secondary.light,
	bad: theme.palette.warning.light,
	empty: theme.palette.warning.gray2
}

const indicators: Record<string, OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>> = {
	identification: ExposureIcon,
	selfEvaluation: StarBorderIcon,
	imageReturn: RecordVoiceOverOutlinedIcon,
	alignment: PeopleAltOutlinedIcon
}

interface Props {
	colorType: string
	indicator: string
	title: string
	text: ReactNode
}

export const KeyIndicatorCard = ({ colorType, indicator, title, text }: Props): ReactElement => {
	const color = { main: 'black' }
	color.main = colors[colorType] ?? 'black'
	const Icon = indicators[indicator]
	const icon = <Icon sx={{ color: color.main, fontSize: 45 }} />

	return (
		<Stack
			sx={{
				width: 200,
				height: 130,
				backgroundColor: color.main + '14',
				border: 'solid',
				borderRadius: 3,
				borderColor: color.main,
				borderWidth: 3
			}}
		>
			<div
				style={{
					height: '40%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				{icon}
			</div>
			<div
				style={{
					height: '60%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column'
				}}
			>
				<Typography
					style={{
						textAlign: 'center'
					}}
					sx={{ color: color.main }}
				>
					{title}
				</Typography>
				<Typography
					style={{
						textAlign: 'center',
						fontWeight: 'bold'
					}}
					sx={{ color: color.main }}
				>
					{text}
				</Typography>
			</div>
		</Stack>
	)
}
